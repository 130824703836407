import React from 'react'
import { Link } from 'gatsby'
import { SwipeableDrawer, Button } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close';

import Navigation from './navigation'
import Logo from '../../../images/logo.png'

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="mobile-nav-toggle">
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            className="mobile-drawer"
          >
            <div className="close-button">
              <Button onClick={toggleDrawer(anchor, false)}><CloseIcon /></Button>
            </div>
            <Navigation />
            <div className="flex justify-end">
              <Link to="/"><img src={ Logo } className="mobile-logo" alt="PROS Marketing Logo" /></Link>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import Header from "./header"
import Footer from "./footer"
import Cookie from '../cookie'

import '../../styles/base.css'
import '../../styles/variables.scss'
import '../../styles/tailwind.css'
import '../../styles/main.css'

const theme = createMuiTheme({
  palette: {
    primary: { // works
      main: '#416BA9',
      text: '#416BA9',
    },
    secondary: { // works
      main: '#DD2E39',
      contrastText: '#fff',
    },  
  },
  typography: {
    fontFamily: [
      '"Poppins"',
      '-apple-system',
      'BlinkMacSystemFont',
    ].join(','),
  },
});

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
  
    return (
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <Footer />
        <Cookie />
      </ThemeProvider>       
    )
  }
  
  export default Layout
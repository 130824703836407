import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Hidden } from '@material-ui/core'
import Navigation from './navigation/navigation'
import Logo from '../../images/logo.png'

import MobileNavigation from "../theme/navigation/mobile-navigation"

export default function Header() {
  return (
    <>
    <header className="white-bg py-12">
    <Container>
      <Grid items container className="flex items-center">
        <Grid item xs={6}>
          <Link to="/"><img src={Logo} alt="PROS Marketing Logo" /></Link>
        </Grid>
        <Grid item xs={6} className="flex items-center justify-end">
          <Hidden smDown>
            <Navigation />
          </Hidden>          
          <Hidden mdUp>
            <MobileNavigation />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  </header>
  </>
  )
}
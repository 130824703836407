import React from 'react'
import CookieConsent from "react-cookie-consent"

export default function Cookie() {
    return (
        <CookieConsent
        location="bottom"
        buttonText="I Accept"
        cookieName="E6cookie"
        className="cookie"
        style={{ background: "#1C355E" }}
        buttonStyle={{ color: "#1C355E", background: "#fff" }}
        expires={150} >
        <p className="text-white">We use cookies to enhance your experience, please accept our cookies.</p>
      </CookieConsent>
    )
}

import React from 'react'
import { Link } from 'gatsby'
import Dropdown from './dropdown/navigation-dropdown'
import { Button, Hidden } from '@material-ui/core'

export default function Navigation() {
  
    return (
        <>        
        <nav className="z-10" id="top">
            <ul className="md:inline-flex md:space-x-12">
                <li><Button><Link to="/" title="Home" activeClassName="active">Home</Link></Button></li>
                    <Hidden smDown>
                        <Dropdown />
                    </Hidden>
                    <Hidden mdUp>
                        {/* If changing the navigation here, change it in the dropdown folder as well */}
                        <li><Button><Link to="/printing" activeClassName="active" title="Printing">Printing</Link></Button></li>
                        <li><Button><Link to="/advertising" activeClassName="active" title="Advertising">Advertising &amp; Events</Link></Button></li>
                        <li><Button><Link to="/web" activeClassName="active" title="Web Development">Web & Digital</Link></Button></li>
                        <li><Button><Link to="/promotional" activeClassName="active" title="Promotional Products">Promotional Products</Link></Button></li>
                        <li><Button><Link to="/wraps-decals" activeClassName="active" title="Vehicle Wraps">Vehicle Wraps</Link></Button></li>
                    </Hidden>
                {/*<li><Button><Link to="#" title="Our Collection" activeClassName="active">Our Collection</Link></Button></li>*/}
                <li><Button><Link to="/contact-us" title="Contact" activeClassName="active">Contact</Link></Button></li>
                <li><Button><Link to="/get-quote" title="Quick Quote" activeClassName="active">Quick Quote</Link></Button></li>
            </ul>
        </nav>
        </>
    )
}
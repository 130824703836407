import React from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function ScrollTop() {
    return (
        <div>
            <p><KeyboardArrowUpIcon />Scroll To Top</p>            
        </div>
    )
}

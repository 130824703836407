import React from 'react'
import { Link } from 'gatsby'
import { Button, Menu, MenuItem } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <>
      <Button id="nav-dropdown" aria-controls="nav-dropdown" aria-haspopup="true" onClick={handleClick}>
          Our Services <KeyboardArrowDownIcon />
      </Button>
      {/* If changing the navigation here, change it in the navigation file as well */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}><Link to="/printing" activeClassName="active" title="Printing">Printing</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/advertising" activeClassName="active" title="Advertising">Advertising &amp; Events</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/web" activeClassName="active" title="Web Development">Web Development</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/promotional" activeClassName="active" title="Promotional Products">Promotional Products</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/wraps-decals" activeClassName="active" title="Wraps & Decals">Wraps &amp; Decals</Link></MenuItem>
      </Menu>
      </>
  );
}